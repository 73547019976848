@import '../../../../scss/index.scss';

.list-categories {
    &_headers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .categories {
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 25px;

        .ant-list-item-meta {
            display: flex;
            align-items: center;
        }

        .ant-list-item-action {
            padding: 10px 20px 10px 0px;
        }
    }

    .button-action {
        border-radius: 25px;
    }

    &_selected {
        color: $primary-color-secondary;
        font-size: large;
    }
}
