@import '../../../scss/index.scss';

.purchases {
}

.search_input {
    width: 100%;

    .ant-select-selector {
        border-radius: 25px;
    }

    .ant-select-selection-search-input {
        border-radius: 25px;
        padding: 5px 5px 5px 1px !important;

        &:focus {
            border-color: $primary-color;
            box-shadow: none;
        }
    }
}
