@import '../../scss/index.scss';

.login-form {
    .ant-form-itm:last-of-type {
        margin: 0;
    }

    &_input {
        border-radius: 25px;
        padding: 0;

        .ant-input-prefix {
            position: absolute;
            height: 100%;
            margin-left: 10px;
            z-index: 1;

            i {
                font-size: 0em + 22px / $defaultFontSize;
            }
        }

        input {
            font-size: 0em + 20px / $defaultFontSize;
            padding: 5px 5px 5px 40px !important;
            border-radius: 25px;

            &:focus {
                border-color: $primary-color;
                box-shadow: none;
            }
        }
    }

    &_button {
        width: 100%;
        font-size: 0em + 20px / $defaultFontSize;
        background-color: $primary-color;
        color: $font-dark;
        height: 42px;
        margin-top: 20px;
        border-radius: 25px;

        &:hover {
            background-color: $primary-color;
            color: $font-dark;
        }

        &:focus,
        &:active {
            background-color: $primary-color;
            border: 0;
        }
    }
}
