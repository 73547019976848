@import '../../scss/index.scss';

.sign-in {
    //background-image: url('../../../assets/img/png/background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        padding: 20px 20px 20px 20px;

        &-logo {
            padding: 60px 20px;
            img {
                width: 100%;
            }
        }

        &-tabs {
            background-color: $background-light;
            width: 300px;
            padding: 20px 20px 20px 20px;
            border-radius: 25px !important;
            box-shadow: 0px 10px 50px 0px;

            .ant-tabs {
                width: 900vw;
                @media (min-width: $media-breackpoint-up-lg) {
                    width: auto;
                }
            }

            .ant-tabs-nav-container {
                height: 60px !important;
            }

            .ant-tabs-nav {
                width: 100%;
            }

            .ant-tabs-tab {
                width: 50%;
                height: 60px !important;
                text-align: center;
                font-size: 0em + 19px / $defaultFontSize;
                border-top: 4px solid $border-grey !important;
                border-radius: 0 !important;
                margin: 0 !important;
                justify-content: center;
                @media (min-width: $media-breackpoint-up-lg) {
                    width: 220px;
                    font-size: 0em + 23px / $defaultFontSize;
                }

                &:hover {
                    color: $font-grey-dark;
                }

                &:before {
                    content: none;
                }

                span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .ant-tabs-tab-active {
                color: $primary-color !important;
                border-top: 4px solid $primary-color !important;
                border-radius: 0 !important;
                border-right: 0 !important;
                border-left: 0 !important;
            }

            .ant-tabs-content {
                padding: 0 16px 16px 16px;
                text-align: center;
            }
        }
    }

    &_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $primary-color-tertiary !important;
        padding: 10px 20px 20px 20px;
    }
}
