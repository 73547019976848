@import '../scss/variables';

.error-404 {
    &_button {
        border-radius: 25px;

        box-shadow: 0px 0px 5px 0px;
        background-color: $primary-color;
        border: 0px;

        &:hover {
            background-color: $primary-color-hover;
            color: $font-light;
        }

        &:focus,
        &:active {
            background-color: $primary-color;
            border: 0;
        }
    }
}
