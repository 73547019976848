@import '../../../scss/index.scss';

.products-sales {
}

.ant-input-affix-wrapper > input.ant-input {
    padding-left: 8px;
    border-radius: 20px;
}

.ant-input-affix-wrapper-disabled {
    color: rgba(0, 0, 0, 0.5);
    background-color: #fff;
}

.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.7);
}

.search_input {
    width: 100%;

    .ant-select-selector {
        border-radius: 25px;
    }

    .ant-select-selection-search-input {
        border-radius: 25px;
        padding: 5px 5px 5px 1px !important;

        &:focus {
            border-color: $primary-color;
            box-shadow: none;
        }
    }
}
