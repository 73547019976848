@import './scss/index.scss';

::selection {
    color: #fff;
    background: $primary-color-secondary;
}

.a {
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        color: #fff;
    }
}

.success {
    border: 1px solid $success;
    background-color: $background-success;
}

.error {
    border: 1px solid $error;
    background-color: $background-error;
}

.ant-row {
    align-items: flex-end;
}

.ant-divider-horizontal {
    margin: 15px 0;
}

// Button
.ant-btn {
    border-radius: 25px;
}

// Menu
.ant-menu-dark .ant-menu-item-selected {
    color: $font-dark;
}

.ant-menu-dark .ant-menu-item-selected .anticon {
    color: $font-dark;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: $font-dark;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: $primary-color;
}

.ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: $font-dark;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primary-color;
}

// Tables
.ant-table-thead > tr > th {
    font-weight: bold;
    text-align: center;
    background: #f1f1f1;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
    background: $primary-color-secondary;
}

.ant-table-summary {
    background: #f1f1f1;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px 33px -16px 33px;
}

// Inputs
.ant-input-affix-wrapper {
    &:active,
    &:focus,
    &:hover {
        border-color: $primary-color-secondary !important;
        color: $primary-color-secondary !important;
        //box-shadow: 0px 0px 5px 0px;
    }

    &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    }
}

.ant-input-affix-wrapper-focused {
    border-color: $primary-color-secondary !important;
    color: $primary-color-secondary !important;

    &:active,
    &:focus,
    &:hover {
        border-color: $primary-color-secondary !important;
        color: $primary-color-secondary !important;
        //sbox-shadow: 0px 0px 5px 0px;
    }
}

.ant-input {
    &:active,
    &:focus,
    &:hover {
        border-color: $primary-color-secondary !important;
        color: $primary-color-secondary !important;
        //sbox-shadow: 0px 0px 5px 0px;
    }
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
}

textarea.ant-input {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 25px;
}

// Selects
.ant-select-selector {
    border-radius: 25px !important;

    &:active,
    &:focus,
    &:hover {
        border-color: $primary-color-secondary !important;
        color: $primary-color-secondary !important;
        box-shadow: 0px 0px 0px 0px;
    }

    &.ant-select-focused {
        border-color: $primary-color-secondary !important;
        box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
        border-radius: 20px;
    }

    &.ant-select-single {
        border-color: $primary-color-secondary !important;
        box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
        border-radius: 20px;
    }

    &.ant-select-open {
        border-color: $primary-color-secondary !important;
        box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
        border-radius: 20px;

        &.ant-select-focused {
            border-color: $primary-color-secondary !important;
            box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
            border-radius: 20px;
        }
    }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $primary-color-secondary !important;
    -webkit-box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    border-radius: 25px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-cuztomize-input) .ant-select-selector {
    border-color: $primary-color-secondary !important;
    -webkit-box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    border-radius: 25px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(254, 189, 24, 0.2);
}

// Suggestion
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(254, 189, 24, 0.2);
}

// Buton icon
.ant-btn-primary {
    color: $font-light;
    background: $primary-color-tertiary;
    border-color: $primary-color-tertiary;
    -webkit-box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);

    &:focus,
    &:hover {
        color: $font-light;
        background: $primary-color-tertiary-light;
        border-color: $primary-color-tertiary-light;
    }
}

// Button icon tranparent
.ant-btn-background-ghost.ant-btn-primary {
    color: $primary-color-tertiary;
    border-color: $primary-color-tertiary;
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
    color: $primary-color-tertiary;
    border-color: $primary-color-tertiary;
}

/*.ant-btn:hover,
.ant-btn:focus {
    color: $primary-color-tertiary;
    background: transparent;
    border-color: $primary-color-tertiary;
}*/

// CheckBox
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color-tertiary;
    border-color: $primary-color-tertiary;
}

.ant-checkbox-checked::after {
    border: 1px solid $primary-color-tertiary;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-color-tertiary;
}

// Radio button
.ant-radio-checked .ant-radio-inner {
    border-color: $primary-color-tertiary;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: $primary-color-tertiary;
}

.ant-radio-input:focus + .ant-radio-inner {
    -webkit-box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
}

.ant-radio-checked::after {
    border: 1px solid $primary-color-tertiary;
}

.ant-radio-inner::after {
    background-color: $primary-color-tertiary;
}

// Date
.ant-picker-input > input {
    text-align: center;
}

.ant-picker {
    border-radius: 25px;
}

.ant-picker:hover {
    border-color: $primary-color-secondary;
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
}

.ant-picker-focused {
    border-color: $primary-color-secondary;
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
    background: $primary-color-secondary;
}

.ant-picker-suffix {
    color: $primary-color-secondary;
}

// Tags
.ant-tag {
    border-radius: 25px;
}

// Switch
.ant-switch {
    background-color: rgba(0, 0, 0, 0.4);
}

.ant-switch-checked {
    background-color: $primary-color-tertiary;
}

.ant-switch-checked:focus {
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
}

.ant-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
}

// Modal
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    border-radius: 25px;
}

// pagination
.ant-pagination-item {
    border-radius: 25px;

    &:hover {
        border-color: $primary-color-tertiary;

        a {
            color: $primary-color-tertiary;
        }
    }
}

.ant-pagination-item-ellipsis {
    &:hover {
        border-color: $primary-color-tertiary;

        a {
            color: $primary-color-tertiary;
        }
    }
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border-radius: 25px;
    &:hover {
        color: $primary-color-tertiary;
        border-color: $primary-color-tertiary;
    }
}

.ant-pagination-item-active {
    border-color: $primary-color-tertiary;

    &:hover {
        border-color: $primary-color-tertiary;
    }
}

.ant-pagination-item-active a {
    color: $primary-color-tertiary;

    &:hover {
        color: $primary-color-tertiary;
    }
}

// Divider
.ant-divider-horizontal.ant-divider-with-text {
    margin: 8px 0;
}

// [ant-click-animating-without-extra-node='true']::after,
// .ant-click-animating-node {
//     box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
// }

// Title
.title-page {
    color: $primary-color;
    font-size: 18px;
}

// Sales
.sales {
    &_row-products {
        align-items: flex-start;
    }

    &_row-document {
        align-items: center;
    }
}

// Form Button submit
.btn-submit {
    width: 100%;
    border-radius: 25px;
    background-color: $primary-color;
    border: 0px;
    padding: 5px 5px 5px 5px !important;
    margin: 10px 0px 0px 0px !important;
    color: $font-dark;

    &:hover {
        background-color: $primary-color;
        color: $font-dark;
    }

    &:focus,
    &:active {
        background-color: $primary-color;
        color: $font-dark;
        border: 0;
    }
}

//Form Inputs
.form-add {
    text-align: left;

    .ant-form-item {
        margin-top: 5px;
        margin-bottom: 5px;

        i {
            color: rgba(0, 0, 0, 0.25);
        }
    }

    &_input {
        width: 100%;
        border-radius: 25px;
        padding: 0;
        margin-top: 5px;

        .ant-input-prefix {
            position: absolute;
            height: 100%;
            margin-left: 10px;
            z-index: 1;

            i {
                font-size: 0em + 22px / $defaultFontSize;
            }
        }

        input {
            width: 100%;
            padding: 5px 5px 5px 40px !important;
            border-radius: 25px !important;

            &:focus,
            &:hover,
            &:active {
                border-color: $primary-color !important;
            }
        }
    }

    &_number {
        border-radius: 25px;
        padding: 0;
        margin-top: 5px;

        .ant-input-prefix {
            position: absolute;
            height: 100%;
            margin-left: 10px;
            z-index: 1;

            i {
                font-size: 0em + 22px / $defaultFontSize;
            }
        }

        input {
            padding: 5px 20px 5px 40px !important;
            border-radius: 25px;
            text-align: right;

            &:focus {
                border-color: $primary-color;
                box-shadow: none;
            }
        }
    }
}

.search_input {
    width: 100%;
    border-radius: 25px;
    padding: 0;
    margin-top: 5px;

    .ant-select-selector {
        border-radius: 25px;
        box-shadow: 0 0 0 0 rgba(41, 41, 41, 0.2);
    }

    .ant-select-selection-search-input {
        border-radius: 25px;
        padding: 5px 5px 5px 1px !important;

        &:focus,
        &:hover,
        &:active {
            border-color: $primary-color !important;
        }
    }
}

// Background
.background_block {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
}

// Paragraph
div.ant-typography,
.ant-typography p {
    margin-bottom: 0em;
}

// Dropdown
.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
    color: $primary-color-tertiary;
    background-color: $primary-color;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: $primary-color-tertiary;
    background-color: $primary-color;
}

.ant-dropdown-menu-item {
    padding: 10px;
    padding-right: 20px;
}

// List
.ant-list-item-action {
    float: right;
}