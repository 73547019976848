@import '../../../../scss/index.scss';

.add-unit-form {
    .form-add {
        text-align: left;

        .ant-form-item {
            margin-top: 5px;
            margin-bottom: 5px;

            i {
                color: rgba(0, 0, 0, 0.25);
            }
        }

        &_input {
            border-radius: 25px;
            padding: 0;

            .ant-input-prefix {
                position: absolute;
                height: 100%;
                margin-left: 10px;
                z-index: 1;

                i {
                    font-size: 0em + 22px / $defaultFontSize;
                }
            }

            input {
                padding: 5px 5px 5px 40px !important;
                border-radius: 25px;

                &:focus {
                    border-color: $primary-color;
                    box-shadow: none;
                }
            }
        }

        .btn-submit {
            width: 100%;
            margin-top: 10px;
            padding: 5px 5px 5px 5px !important;
            border-radius: 25px;
        }
    }
}
