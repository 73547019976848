@import '../../scss/index.scss';

.menu-top {
    display: flex;

    button {
        color: $font-light;
        &:hover {
            color: $primary-color;
        }
        &:focus,
        &:active {
            color: $font-light;
        }
    }

    &_left {
        &-logo {
            width: 55px;
            padding: 0 5px;
        }

        &-logo-letras {
            height: 30px;
        }

        &-title {
            position: fixed;
        }
    }

    &_right {
        position: absolute;
        right: 0;
        top: 0;
    }
}
