.layout-admin {
    transition: margin-left 0.2s;

    &_header {
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0;
        height: 65px;
    }

    &_content {
        min-height: calc(100vh - 65px);
        padding: 90px 25px 25px 25px;
    }

    &_footer {
        padding: 20px;
    }
}
