.list-units {
    &_headers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .units {
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 25px;

        .ant-list-item-meta {
            display: flex;
            align-items: center;
        }
    }

    .button-action {
        border-radius: 25px;
    }
}
