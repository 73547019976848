@import '../../../scss/index.scss';

.edit-product {
    .upload-image {
        display: table;
        margin: 0 auto;
        border: 2px solid #9a9a9a;
        border-style: dashed;
        border-radius: 100px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .button-action {
        border-radius: 25px;
    }

    .form-edit {
        text-align: left;

        .ant-form-item {
            margin-top: 5px;
            margin-bottom: 5px;

            i {
                color: rgba(0, 0, 0, 0.25);
            }
        }

        &_input {
            border-radius: 25px;
            padding: 0;

            .ant-input-prefix {
                position: absolute;
                height: 100%;
                margin-left: 10px;
                z-index: 1;

                i {
                    font-size: 0em + 22px / $defaultFontSize;
                }
            }

            input {
                padding: 5px 5px 5px 40px !important;
                border-radius: 25px;

                &:focus {
                    border-color: $primary-color;
                    box-shadow: none;
                }
            }
        }

        &_number {
            border-radius: 25px;
            padding: 0;

            .ant-input-prefix {
                position: absolute;
                height: 100%;
                margin-left: 10px;
                z-index: 1;

                i {
                    font-size: 0em + 22px / $defaultFontSize;
                }
            }

            input {
                padding: 5px 20px 5px 40px !important;
                border-radius: 25px;
                text-align: right;

                &:focus {
                    border-color: $primary-color;
                    box-shadow: none;
                }
            }
        }

        &_select {
            .ant-select-selector {
                border-radius: 25px;
            }
        }

        &_switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 10px;
            }
        }

        &_label {
            display: flex;
            height: 100%;
            align-items: center;
        }

        .btn-submit {
            width: 100%;
            margin-top: 10px;
            padding: 5px 5px 5px 5px !important;
            border-radius: 25px;
        }
    }
}
