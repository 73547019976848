@import '../../../../scss/index.scss';

.add-cash-register-form {
    &_header-doc {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .button-action {
        border-radius: 25px;
    }
}
